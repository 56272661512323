@import "/node_modules/@forgerock/backstage-ui-components/src/scss/variables";

$enable-negative-margins: true;
$body-bg: $backstage-color-gray-100;
$body-color: $backstage-color-gray-800;
$link-color: $backstage-color-lightblue;
$link-hover-color: $backstage-color-lightblue;
$link-decoration: none;
$link-hover-decoration: underline;
$font-family-base: 'Open Sans', sans-serif;

@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

