@import "styles/variables";
@import "/node_modules/@forgerock/backstage-ui-components/src/scss/ui-components";
@import "/node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/bootstrap-icons";
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
  position: relative;
  min-height: 100%;
}

.theme-dark {
  background-color: $backstage-color-gray-900;
}

.bg-developers {
  background: url("/community/assets/developers/bg-developers.png") 100% 0 no-repeat;
}

.text-clip {
  color: transparent;
  background-clip: text !important;
}

.container {
  max-width: map_get($container-max-widths, xl);
}
